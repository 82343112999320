import React, { useEffect, useRef } from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';

import { grid, wrapper, form, formGrid, thankYouBox, sectionBox } from './sign-up.module.scss';
import { TSectionSignInUp } from '../../templates/sign-in-up';
import { registrationForm } from '../../formik/registration.form';
import { useUser } from '../../hooks/use-user';
import useRtkQueryFormError from '../../hooks/use-rtk-query-form-error';
import useTranslations from '../../hooks/use-translations';

import Section from '../hoc/section';
import FormGenerator from '../molecules/form-generator';
import HorizontalTabNav from '../molecules/horizontal-tab-nav';
import ThankYouBox from '../molecules/thank-you-box';

export interface ISignUpProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: TSectionSignInUp;
}

export type TSignUpLocationState = { isOrderRegistration?: boolean } | null;

export default function SignUp({ className, section, TitleTag = 'h2' }: ISignUpProps) {
    const location = useLocation();
    const locationState = location.state as TSignUpLocationState;
    const isOrderRegistration = locationState?.isOrderRegistration;

    const { sectionId, css, style } = section;
    const availablePaths = section.settings.availablePaths;
    const [title, description, ...consents] = section.content.texts;
    const [successButton] = section.content.buttons;
    const formTranslations = useTranslations('SignInUpNav');

    const sectionRef = useRef<HTMLElement>(null);
    const userState = useUser();
    const { formikRef, globalErrorMessage } = useRtkQueryFormError(userState.register.errors);

    useEffect(() => {
        if (!sectionRef.current || !userState.register.isSuccess) return;
        if (!isOrderRegistration || !userState.isLoggedIn) {
            sectionRef.current.scrollIntoView();
        }
    }, [isOrderRegistration, userState.isLoggedIn, userState.register.isSuccess]);

    if (isOrderRegistration && userState.register.isSuccess && userState.isLoggedIn) {
        navigate(availablePaths.order || '/', { replace: true });
    }

    if (userState.register.isUninitialized && userState.isLoggedIn && availablePaths.order) {
        navigate(availablePaths['user-data'] || '/', { replace: true });
    }

    return (
        <Section
            sectionId={sectionId}
            className={`${className} ${sectionBox}`}
            classes={{ container: grid }}
            css={css}
            style={style}
            ref={sectionRef}
        >
            <div className={wrapper}>
                {availablePaths.login &&
                    availablePaths.register &&
                    !userState.register.isSuccess && (
                        <HorizontalTabNav
                            links={[
                                { to: availablePaths.login, label: formTranslations.links.login },
                                {
                                    to: availablePaths.register,
                                    label: formTranslations.links.register,
                                    LabelTag: TitleTag,
                                },
                            ]}
                        />
                    )}
                {!userState.register.isSuccess && (
                    <FormGenerator
                        formRef={formikRef}
                        className={form}
                        formClassName={formGrid}
                        formikProps={{ onSubmit: (values) => userState.register.fetch(values) }}
                        name="RegistrationForm"
                        fields={registrationForm}
                        extraFieldsProps={{ consents }}
                        errorMessage={globalErrorMessage}
                        submitLoading={userState.register.isLoading}
                    />
                )}
                {userState.register.isSuccess && (
                    <ThankYouBox
                        className={thankYouBox}
                        data={{
                            title: title || '',
                            description: description || '',
                            button: successButton,
                        }}
                    />
                )}
            </div>
        </Section>
    );
}
